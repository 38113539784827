
.TelljaShoppingCart {
  &.ShoppingCartRow--compact {
    strong {
      color: #057A55
    }
    span.Icon{
      margin-left: 0.5rem;
      display: inline-block;
      width: 16px;

      .Icon-base {
        fill: #057A55;
      }
    }
  }
}

.ShoppingCart-promotionOption--tellja {

  margin-top: 1rem;

  .ShoppingCartRow-inner {
    padding-bottom: 1rem;
  }

  &.ShoppingCartRow--full {
    .ShoppingCartRow-col:first-child {
      width: 100%
    }
    .ShoppingCartRow-col:not(:first-child) {
      display: none;
    }
  }
}

.ShoppingCart-promotionOption--telljaSuccess {
  color: #057A55;

  .ShoppingCartRow-col {
    display: flex;

    &:first-child {
      padding-left: 0.75rem;
    }


    span.Icon {
      margin-right: 0.5rem;
      width: 16px;

      .Icon-base {
        fill: #057A55;
      }
    }
  }
}
.TelljaRegistrationForm{
  .FormFooter-buttons .FormFooter-link--primary .TextLink.TextLink--asButton {
    .TextLink-text:after {
      display: none;
    }
    width: fit-content;
    margin-left: auto;
  }
}
