/** @define ShoppingCart */
$componentClass: '.ShoppingCart';

@include media($media_xs) {
  [data-module-type="ShoppingCart"] .GlobalSection-inner {
    padding-left: 0;
    padding-right: 0;
  }
}

.ShoppingCart {
  sup { color: $color-gray100; }
  .TextInfo {
    .Icon-highlight,
    .Icon-base {
      fill: $color-gray100;
    }
  }

  &-option {
    border-top: none !important;
    font-weight: $font-weight-bold;
  }

  &-promotionOption {
    border-top: none !important;
  }

  &-esimHintPua {
    #{$componentClass}-infoIcon {
      .TextLink-icon {
        width: $copy-secondary_font-size;
      }

      .Icon-base,
      .Icon-highlight {
        fill: $color-primary;
      }
    }
  }

  &--compact,
  &--widget {
    .TextUnit {
      font-size: rem-calc(14);
    }

    .TextUnit-postfix {
      margin-left: 0.1rem;
    }

    .ShoppingCartRow {
      &-col:first-child { padding-left: 0 }
      &-col:last-child { padding-right: 0 }
    }

    .ShoppingCartRow--compact, .ShoppingCartRow--widget {
      border-top: 1px solid $color-gray50;
      margin-top: rem-calc(15);
    }

    .ShoppingCartRow--compact:nth-of-type(2) {
      border-top: 2px solid $color-gray50 !important;
      margin-top: rem-calc(13);
    }

    #{$componentClass}-header {
      .TextHeadline--xxs {
        font-size: rem-calc(12);
      }
    }

    #{$componentClass}-option,
    #{$componentClass}-promotionOption {
      .ShoppingCartRow-col {
        padding-top: $global-padding * .25 !important;
        padding-bottom: $global-padding * .25 !important;
      }
    }

    #{$componentClass}-product + #{$componentClass}-option,
    #{$componentClass}-product + #{$componentClass}-promotionOption {
      margin-top: $global-padding * .15;
    }

    #{$componentClass}-total {
      border-top: 2px solid $color-gray50 !important;
      margin-top: rem-calc(7);
      padding-top: rem-calc(16);
      .TextUnit {
        font-size: 1em;
        font-weight: bold;
      }
      .TextUnit-prefix {
        display: block;
        margin-right: 0;
        color: $color-gray100;
        font-size: $h-xxxs_font-size;
        line-height: $copy-small_line-height;
        letter-spacing: $copy-small_letter-spacing;
        text-transform: uppercase;
        text-align: right;
        margin-bottom: $global-padding * .25;
      }
    }

    .u-col3,  { width: 22% !important; }
    .u-col6 { width: 56% !important; }

    @include media($media_s) {
      .u-sCol3,  { width: 22% !important; }
      .u-sCol6,  { width: 56% !important; }
    }

  }

  &--widget {
    #{$componentClass}-product {
      .TextUnit {
        font-size: 12px;
      }
    }
  }

  &--compact {
    background: $gradient-neutral;
    border-radius: $border-radius-m;
    #{$componentClass}-inner {
      padding: $global-padding;
    }
  }

  &--widget {
    #{$componentClass}-total {
      border-top: 2px solid $color-gray100 !important;
    }
  }

  &--full {
    @include media($media_s) {
      .u-sCol6:not(.u-sCol12) {
        width: 60% !important;
      }

      .u-sCol3 {
        width: 20% !important;
      }
      .TextUnit {
        font-size: .9em;
      }
    }

    .ShoppingCartRow + .ShoppingCartRow {
      border-top: 2px solid $color-gray15;
    }

    #{$componentClass}-product {
       &.ShoppingCartRow--adjacentBottom .ShoppingCartRow-col {
         padding-bottom: $global-padding * .5 !important;
       }
     }

    #{$componentClass}-simTypeOption {
      border-top: 2px solid $color-gray15;

      .deviceCheckLink {
        cursor: pointer;
      }

      .FieldRadio-label::before {
        margin-top: rem-calc(4);
        display: inline-block;
        vertical-align: top;
      }
      #{$componentClass}-customLabel {
        display: inline-block;
        padding-right: rem-calc(10);
        #{$componentClass}-customLabelHint {
          white-space: initial;
          color: $color-success;
          &--orange {
            color: $color-primary;
          }
        }
      }
    }

    #{$componentClass}-option,
    #{$componentClass}-promotionOption {
      &.ShoppingCartRow--adjacentTop .ShoppingCartRow-col {
        padding-top: $global-padding * .5 !important;
      }
      &.ShoppingCartRow--adjacentBottom .ShoppingCartRow-col {
        padding-bottom: $global-padding * .5 !important;
      }
      .FormOptionBox-input + .FormOptionBox-label {
        display: block;

        .FormOptionBox-labelInner > * {
          display: inline;
        }
      }

      @include media($media_s) {
        font-size: .9em;
      }
    }

    #{$componentClass}-header {
      padding: $global-padding * .5 0;
      background: $gradient-neutral;
      border-radius: $border-radius-m;

      > * {
        padding-left: $global-padding * .5;
        padding-right: $global-padding * .5;
      }

      > *:not(:first-child) {
        padding-left: 0;
      }

      @include media($media_xs) {
        > *:first-child {
          padding-left: rem-calc(10);
          padding-right: 0;
        }
        > *:not(:first-child) {
          padding-right: rem-calc(10);
          padding-left: 0;
        }
      }

      @include media($media_m-l) {
        > *:not(:first-child) {
          padding-right: $global-padding;
        }
        > *:first-child {
          padding-left: $global-padding;
          padding-right: $global-padding;
        }
      }
    }

    #{$componentClass}-promotionsHeadline,
    #{$componentClass}-promotion {
      background: $gradient-neutral;
      @include media($media_m-l) {
        margin-left: 0.75rem;
        margin-right: 0.75rem
      }
    }

    #{$componentClass}-promotionsHeadline {
      border-top-right-radius: $border-radius-m;
      border-top-left-radius: $border-radius-m;
      margin-top: rem-calc(16);
    }

    #{$componentClass}-promotion {
      border-bottom-right-radius: $border-radius-m;
      border-bottom-left-radius: $border-radius-m;

      &:has(+ #{$componentClass}-promotion) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      + .ShoppingCartRow {
        border-top: none;
      }
    }

    #{$componentClass}-shipping,
    #{$componentClass}-total,
    #{$componentClass}-footer {
      background: $gradient-neutral;
    }

    #{$componentClass}-shipping {
      border-top-right-radius: $border-radius-m;
      border-top-left-radius: $border-radius-m;
      .ShoppingCartRow-col {
        padding-bottom: $global-padding * .5;
      }
    }

    #{$componentClass}-footer {
      padding-bottom: $global-padding;
      border-bottom-left-radius: $border-radius-m;
      border-bottom-right-radius: $border-radius-m;
      .TextCopy {
        padding-right: $global-padding;
        padding-left: $global-padding;
        font-size: rem-calc(14);
        line-height: 1.1rem;
       }

      @include media($media_s) {
        .TextCopy {
          padding-right: $global-padding * .5;
          padding-left: $global-padding * .5;
        }
       }
      }

    #{$componentClass}-total {
      .ShoppingCartRow-col {
        padding-top: 0;
        padding-bottom: rem-calc(12);
      }

      @include media($media_s) {
        .TextUnit-prefix {
          display: block;
          margin-right: 0;
          margin-bottom: $global-padding * .25;
        }

        .ShoppingCartRow-col .TextUnit {
          font-size: 1em;
        }
      }

      @include media($media_m-l) {
        .ShoppingCartRow-col:first-child {
          display: flex;
          align-items: flex-end;
          > * + * {
            margin-left: $global-padding * .5;
          }
        }
        .ShoppingCartRow-col:not(:first-child) {
          .TextUnit {
            font-size: 1.2em;
          }
        }
      }
    }
  }

  &--accordion {
    position: relative;
    padding-bottom: rem-calc(8);

    #{$componentClass}-inner {
      padding-bottom: 0;
    }

    #{$componentClass}-toggle {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 1;
    }

    #{$componentClass}-icon {
      display: block;
      width: rem-calc(15);
      height: $global-padding;
      margin: $global-padding * .25 auto 0;
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      transition: transform $timing-duration $timing-easing;
    }

    &:not(.is-open) {
      .ShoppingCartRow:not(.ShoppingCart-total) {
        display: none;
      }

      #{$componentClass}-total {
        margin-top: 0;
        border-top: none !important;
        .TextUnit-prefix {
          display: none;
        }
      }
    }

    &.is-open {
      #{$componentClass}-toggle {
        top: auto;
        height: rem-calc(80);
      }
      #{$componentClass}-icon {
        transform: rotate(180deg);
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-header,
  #{$componentClass}-shipping,
  #{$componentClass}-total,
  #{$componentClass}-footer,
  #{$componentClass}-promotionsHeadline,
  #{$componentClass}-promotion,
  #{$componentClass}--compact {
    background: $color-gray-light-darkmode;
  }

  #{$componentClass}--full {
    .ShoppingCartRow + .ShoppingCartRow {
      border-top: 0;
    }
    #{$componentClass}-product,
    #{$componentClass}-mnp {
      border-bottom: 2px solid $color-gray50;
    }
    .ShoppingCartRow--adjacentBottom {
      border: 0;
    }
  }

  #{$componentClass}-icon {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" fill="#{$color-white}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
  }
}
